import { useForm } from "react-hook-form";
import { User } from "../models/users";
import { LoginCredantials } from "../network/users_api";
import * as UsersApi from "../network/users_api";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import TextInputField from "./form/TextInputField";
import styleUtils from "../styles/utils.module.css";
import { useState } from "react";
import { UnauthorizedError } from "../errors/http_errors";

interface LoginModalProps {
    onDismiss: () => void,
    onLoginSuccessfull: (user: User) => void,
}

const LoginModal = ({ onDismiss, onLoginSuccessfull }: LoginModalProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginCredantials>();

    async function onSubmit(credentials: LoginCredantials) {
        try {
            const user = await UsersApi.login(credentials);
            onLoginSuccessfull(user);
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                setErrorText(error.message);
            } else {
                alert(error);
            }
            console.error(error);
        }
    }

    return (
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Log in
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorText &&
                <Alert variant="danger">
                    {errorText}
                </Alert>
                }
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="neptunCode"
                        label="Neptun Code"
                        type="text"
                        placeholder="ABC123"
                        register={register}
                        registerOptions={{ required: "Required" }}
                        error={errors.neptunCode}
                    />
                    <TextInputField
                        name="password"
                        label="Password"
                        type="password"
                        placeholder="password"
                        register={register}
                        registerOptions={{ required: "Required" }}
                        error={errors.password}
                    />
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={styleUtils.width100}
                    >
                        Log in
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;