import { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { Sections as SectionsModel } from '../../models/sections';
import * as AssignmentsApi from "../../network/assignments_api";
import DropdownSectionField from '../form/DropdownSectionField';

interface SectionProps {
    readonly courseID: string;
    readonly assignmentID: String[];
    onSectionChoosen: (sectionID: string) => void;
}

const Section = ({ courseID, assignmentID, onSectionChoosen }: SectionProps) => {
    const [sections, setSection] = useState<SectionsModel[]>([]);
    const [sectionLoading, setSectionLoading] = useState(true);
    const [showSectionLoadingError, setShowSectionLoadingError] = useState(false);

    async function passSectionBack(sectionSelected: string) {
        onSectionChoosen(sectionSelected);
    }

    useEffect(() => {
        async function loadSections() {
            try {
                setShowSectionLoadingError(false);
                setSectionLoading(true);
                const section = await AssignmentsApi.fetchSections(courseID!.toString(), assignmentID.toString());
                setSection(section);
            } catch (error) {
                console.error(error);
                setShowSectionLoadingError(true);
            } finally {
                setSectionLoading(false);
            }
        }
        loadSections();
    }, [courseID, assignmentID]);

    return (
        <>
            {sectionLoading && <Spinner animation='border' variant='dark' />}
            {showSectionLoadingError && <p>Something went wrong. Please refersh the page.</p>}
            {!sectionLoading &&
                <>
                    <DropdownSectionField
                        sections={sections}
                        name="Sections"
                        type="dropdown"
                        onSectionSelected={(choosenSectionID) => {
                            passSectionBack(choosenSectionID);
                        }}
                    />
                </>
            }
        </>
    );
}

export default Section;