import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Button, Col, Row, Form, Image } from "react-bootstrap";
import { User as UserModel } from "../models/users";
import { formatDate } from "../utils/formatDate";
import styleUtils from "../styles/utils.module.css";
import "../styles/customBootsrapOverride.css";
import { ProfilePageCredantials } from '../network/users_api';
import * as UserApi from "../network/users_api";
import TextInputFieldInline from "./form/TextInputFieldInline";

interface ProfileProps {
    user: UserModel,
    className?: string,
    onProfileSaved: (user: UserModel) => void,
}

const Profile = ({ user, className, onProfileSaved }: ProfileProps) => {
    const {
        firstName,
        lastName,
        neptunCode,
        email,
        password,
        profilePic,
        userRole,
        sharedSecret,
        createdAt,
        updatedAt
    } = user;

    let dateText: string;
    if (updatedAt > createdAt) {
        dateText = "Updated: " + formatDate(updatedAt);
    } else {
        dateText = "Created: " + formatDate(createdAt);
    }

    const profilePicList = ['bear.png', 'boar.png', 'cat.png', 'dog.png', 'fox.png', 'hamster.png', 'koala.png', 'lion.png', 'monkey_canthear.png', 'monkey_cantsee.png', 'monkey_cantspeak.png', 'monkey.png', 'mouse.png', 'owl.png', 'panda.png', 'rabbit.png', 'tiger.png', 'unicorn.png', 'wolf.png'];
    // Changing the choosen profile pic
    function handleChange(choosenPic: string) {
        const radioElement = document.getElementById(choosenPic) as unknown as HTMLFormElement;
        radioElement.checked = true;
        const element = document.getElementById("choosenPicImgLive") as unknown as HTMLImageElement;
        element.src = "./profilePics/" + choosenPic;
        console.log(choosenPic);
    };

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<ProfilePageCredantials>({
        defaultValues: {
            email: user?.email || "",
            password: user?.password || "",
            profilePic: user?.profilePic || "",
            sharedSecret: user?.sharedSecret || "",
        }
    });

    async function onSubmit(data: ProfilePageCredantials) {
        try {
            let userResponse: UserModel;
            if (user) {
                userResponse = await UserApi.udpateUser(data);
                onProfileSaved(userResponse);
                alert('Form submitted successfully!');
            }
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return (
        <>
            <Form id="updateUserData" onSubmit={handleSubmit(onSubmit)} >
                <h2>Profile details</h2>

                <Form.Group as={Row} className="mb-2" controlId="formHorizontalName">
                    <Form.Label column sm={3}>Name:</Form.Label>
                    <Col sm={9}>
                        <Form.Control disabled type="text" placeholder={`${lastName} ${firstName}`} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2" controlId="formHorizontalNeptunCode">
                    <Form.Label column sm={3}>Netun Code:</Form.Label>
                    <Col sm={9}>
                        <Form.Control disabled type="text" placeholder={`${neptunCode}`} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2" controlId="formHorizontalUserRole">
                    <Form.Label column sm={3}>User Role:</Form.Label>
                    <Col sm={9}>
                        <Form.Control disabled type="text" placeholder={`${userRole}`} />
                    </Col>
                </Form.Group>

                <TextInputFieldInline
                    name="email"
                    label="Email"
                    type="email"
                    placeholder={`${email}`}
                    register={register}
                    error={errors.email}
                />

                <TextInputFieldInline
                    name="password"
                    label="Password"
                    type="password"
                    placeholder={`●●●●●●●●●●`}
                    register={register}
                    error={errors.password}
                />

                <TextInputFieldInline
                    name="sharedSecret"
                    label="Access Token"
                    type="text"
                    placeholder={`${sharedSecret}`}
                    register={register}
                    error={errors.sharedSecret}
                />

                <Form.Group as={Row} className="mb-3">
                    <div className="col-md-3">
                        <div className="row">
                            <Form.Label column sm={12}>
                                Profile Picture:
                            </Form.Label>
                            <Image
                                src={"./profilePics/" + profilePic}
                                alt="shows choosen profile picture"
                                id="choosenPicImgLive"
                                className={`${styleUtils.choosenPicImgLive}`} />
                        </div>
                    </div>

                    <Col sm={12} md={9} >
                        {
                            profilePicList.map(pic => (
                                <>
                                    {pic === profilePic
                                        ? <Form.Label key={profilePic}>
                                            <Form.Check
                                                inline
                                                key={pic}
                                                type="radio"
                                                label={`${profilePic}`}
                                                name="profilePic"
                                                value={`${profilePic}`}
                                                id={`${profilePic}`}
                                                className={`${styleUtils.chooseProfilePic}`}
                                                onClick={() => handleChange(profilePic)}

                                            />
                                            <Image
                                                key={"img-" + profilePic}
                                                src={"./profilePics/" + profilePic}
                                                roundedCircle={false}
                                                className={`${styleUtils.profilePicImg} ${styleUtils.activeProfilePic}`}
                                                onClick={() => handleChange(profilePic)}></Image>
                                        </Form.Label>
                                        : <Form.Label key={pic}>
                                            <Form.Check
                                                inline
                                                key={pic}
                                                type="radio"
                                                label={pic}
                                                name="profilePic"
                                                value={pic}
                                                id={pic}
                                                className={`${styleUtils.chooseProfilePic}`}
                                                onClick={() => handleChange(pic)}
                                            />
                                            <Image
                                                src={"./profilePics/" + pic}
                                                roundedCircle={false}
                                                className={`${styleUtils.profilePicImg}`}
                                                onClick={() => handleChange(pic)}></Image>
                                        </Form.Label>}
                                </>
                            ))}
                    </Col>
                </Form.Group>
                <Col className="col-2 offset-10">
                    <Button variant="dark" form="updateUserData" type="submit">
                        Update
                    </Button>
                </Col>


            </Form >
        </>
    );
}

export default Profile;