import { Button } from "react-bootstrap";

interface NavBarLoggedoutViewProps {
    onLoginClicked: () => void,
}

const NavBarLoggedOutView = ({onLoginClicked}:NavBarLoggedoutViewProps) => {
    return ( 
        <>
            <Button onClick={onLoginClicked}>Log in</Button>
        </>
     );
}
 
export default NavBarLoggedOutView;