import { Dropdown, Form } from "react-bootstrap";
import { User } from '../../models/users';
import { useState } from "react";

interface DropdownUsersFieldProps {
    users: User[];
    name: string,
    [x: string]: any,
    onUserSelected: (userChoosen: User) => void,
}

const DropdownUsersField = ({ users, name, onUserSelected, ...props }: DropdownUsersFieldProps) => {
    const [selectedUser, setSelectedUser] = useState<User>();

    const choosenDropdowItem = (user: User) => {
        setSelectedUser(user);
        onUserSelected(user);
    };

    return (
        <Form.Group className="mb-3" controlId={name + "-input"}>
            <Dropdown className="w-100">
                <Dropdown.Toggle variant="dark" id="dropdown-basic" className="w-100">
                    {selectedUser ? selectedUser.lastName + " " + selectedUser.firstName + " (" + selectedUser.neptunCode + ")" : "Choose a user"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                    {users.sort((a, b) => a.lastName.localeCompare(b.lastName)).map(user => (
                        <Dropdown.Item key={user._id} onClick={() => choosenDropdowItem(user)}>{user.lastName + " " + user.firstName + " (" + user.neptunCode + ")"}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
}

export default DropdownUsersField;