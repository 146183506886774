import { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Check2, Check2All } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { Assignments as AssignmentsModel } from '../models/assignments';
import { Students } from '../models/students';
import * as AssignmentsApi from "../network/assignments_api";
import styles from "../styles/CoursePage.module.css";
import Section from './AssignmentComponents/Sections';
import SmallTaskResultsForSection from './AssignmentComponents/SmallTaskResultsForSection';
import SmallTaskResultsForStudent from './AssignmentComponents/SmallTaskResultsForStudent';
import Student from './AssignmentComponents/Students';
import DropdownAssignmentField from './form/DropdownAssignemntField';

const AssignmentLoggedInView = () => {
    const course_id = useParams();
    const [assignments, setAssignment] = useState<AssignmentsModel[]>([]);
    const [assignmentLoading, setAssignmentLoading] = useState(true);
    const [showAssignmentLoadingError, setShowAssignmentLoadingError] = useState(false);
    const [assignmentIDSelected, setAssignmentIDselected] = useState<String[]>([]);

    const [sectionVisible, setSectionVisible] = useState(false);
    const [sectionID, setSectionID] = useState<String>();

    const [studentsVisible, setStudentsVisible] = useState(false);
    const [studentSelected, setStudentSelected] = useState<Students>();

    const [validateBtnVisible, setValidateBtnVisible] = useState(false);
    const [smallTaskResultVisible, setSmallTaskResultVisible] = useState(false);

    const [validateSectionBtnVisible, setValidateSectionBtnVisible] = useState(false);
    const [sectionStudentsSelected, setSectionStudentsSelected] = useState<Students[]>([]);
    const [smallTaskSectionResultVisible, setSmallTaskSectionResultVisible] = useState(false);

    useEffect(() => {
        async function loadAssignments() {
            try {
                setShowAssignmentLoadingError(false);
                setAssignmentLoading(true);
                const assignment = await AssignmentsApi.fetchAssignment(course_id.courseID!.toString());
                setAssignment(assignment);
            } catch (error) {
                console.error(error);
                setShowAssignmentLoadingError(true);
            } finally {
                setAssignmentLoading(false);
            }
        }
        loadAssignments();
    }, []);

    return (
        <>
            <Row xs={12} md={12} xl={12} className={`g-4 mb-3 ${styles.coursesGrid}`}>
                <Col className="d-flex align-items-center justify-content-center">
                    {assignmentLoading && <Spinner animation='border' variant='dark' />}
                    {showAssignmentLoadingError && <p>Something went wrong. Please refersh the page.</p>}
                    {!assignmentLoading &&
                        <DropdownAssignmentField
                            assignments={assignments}
                            name="Assignments"
                            type="dropdown"
                            onAssignmentSelected={(assignmentIDs) => {
                                setAssignmentIDselected(assignmentIDs);
                                setSectionVisible(true);
                                setStudentsVisible(false);
                                setValidateBtnVisible(false);
                                setSmallTaskResultVisible(false);
                                setValidateSectionBtnVisible(false);
                            }}
                        />
                    }
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    {sectionVisible &&
                        <Section
                            courseID={course_id.courseID!.toString()}
                            assignmentID={assignmentIDSelected}
                            onSectionChoosen={(choosenSection) => {
                                setSectionID(choosenSection);
                                setStudentsVisible(true);
                                setValidateBtnVisible(false);
                                setSmallTaskResultVisible(false);
                                setValidateSectionBtnVisible(false);
                                setSmallTaskSectionResultVisible(false);
                            }}
                        />
                    }
                </Col>
                <Col>
                    {validateSectionBtnVisible &&
                        <Button variant="dark" form="" type="submit" onClick={() => {
                            setSmallTaskSectionResultVisible(true);
                            setValidateBtnVisible(false);
                            setSmallTaskResultVisible(false);
                        }}><Check2All size={20} /> Section</Button>
                    }
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    {studentsVisible &&
                        <Student
                            courseID={course_id.courseID!.toString()}
                            assignmentID={assignmentIDSelected}
                            sectionID={sectionID!.toString()}
                            onStudentChoosen={(choosenStudent) => {
                                setStudentSelected(choosenStudent);
                                setValidateBtnVisible(true);
                                setSmallTaskResultVisible(false);
                            }}
                            onSectionChoosen={(choosenSectionStudents) => {
                                setSectionStudentsSelected(choosenSectionStudents);
                                setValidateSectionBtnVisible(true);
                                setSmallTaskResultVisible(false);
                            }}
                        />
                    }
                </Col>
                <Col>
                    {validateBtnVisible &&
                        <Button variant="dark" form="" type="submit" onClick={() => {
                            setSmallTaskResultVisible(true);
                            setValidateBtnVisible(false);
                            setSmallTaskSectionResultVisible(false);
                        }}><Check2 size={20} /> Student</Button>
                    }
                </Col>
            </Row >
            <Row xs={12} md={12} xl={12} className={`g-4 ${styles.coursesGrid}`}>
                <Col></Col>
                <Col xs={8} md={8} xl={9}>
                    {smallTaskResultVisible &&
                        <SmallTaskResultsForStudent
                            courseID={course_id.courseID!.toString()}
                            assignmentID={assignmentIDSelected}
                            studentID={studentSelected!.id}
                            studentName={studentSelected!.name}
                            workflow_state={studentSelected!.workflow_state}
                            url={studentSelected!.url}
                        /> 
                    }
                    {smallTaskSectionResultVisible &&
                        <SmallTaskResultsForSection
                            courseID={course_id.courseID!.toString()}
                            sectionID={sectionID!.toString()}
                            assignmentID={assignmentIDSelected}
                            studentsList={sectionStudentsSelected!}
                        />
                    }
                </Col>
                <Col></Col>
            </Row >
        </>
    );
}

export default AssignmentLoggedInView;