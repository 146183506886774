import Card from 'react-bootstrap/Card';
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Course as CourseModel } from "../models/course";
import styles from "../styles/Course.module.css";
import styleUtils from "../styles/utils.module.css";
import { formatDate } from "../utils/formatDate";

interface CourseProps {
    course: CourseModel,
    onEditCourseClicked: (course: CourseModel) => void,
    onDeleteCourseClicked: (course: CourseModel) => void,
    className?: string,
}

const Course = ({ course, onEditCourseClicked, onDeleteCourseClicked, className }: CourseProps) => {
    const {
        course_id,
        title,
        type,
        createdAt,
        updatedAt,
    } = course;

    let dateText: string;
    if (updatedAt > createdAt) {
        dateText = "Updated: " + formatDate(updatedAt);
    } else {
        dateText = "Created: " + formatDate(createdAt);
    }

    const navigate = useNavigate();
    const navigateToAssignments = () => {
        navigate(`/courses/${course_id}/assignments`);
    };

    return (
        <Card
            className={`h-100`}
            onClick={navigateToAssignments}
        >
            <Card.Body className={styles.cardBody}>
                <Card.Img variant="top" src="coursePics/programming.png" />
                <Card.Title>
                    {title}
                </Card.Title>
                <Card.Subtitle className={styleUtils.flexCenter}>
                    {type}
                    <div className="text-muted ms-auto">{course_id}</div>
                </Card.Subtitle>
                <Card.Text>
                    <MdDelete
                        className="text-muted ms-auto"
                        onClick={(e) => {
                            onDeleteCourseClicked(course);
                            e.stopPropagation();
                        }}
                    />
                    <MdEdit
                        className="text-muted ms-auto"
                        onClick={(e) => {
                            onEditCourseClicked(course);
                            e.stopPropagation();
                        }}
                    />
                </Card.Text>
            </Card.Body>
            <Card.Footer className={`text-muted ${styles.dateText}`}>
                {dateText}
            </Card.Footer>
        </Card>
    )
}

export default Course;