import { useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import * as UserApi from "../../network/users_api";
import { AdminPageCredantials } from '../../network/users_api';
import "../../styles/customBootsrapOverride.css";
import TextInputFieldInline from '../form/TextInputFieldInline';

interface DashboardProps {
    className?: string,
}

const AddNewUserDashboard = ({ className }: DashboardProps) => {
    const [user, setUser] = useState<AdminPageCredantials>();
    const [selectedRole, setSelectedRole] = useState<string>();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            neptunCode: user?.neptunCode || "",
            email: user?.email || "",
            userRole: user?.userRole || selectedRole || "",
        }
    });

    const choosenDropdowItem = (role: string) => {
        setSelectedRole(role);
    };

    async function onSubmit(data: AdminPageCredantials) {
        try {
            await setUser(data);
            if (user && selectedRole){
                data.userRole = selectedRole;
                await UserApi.addUser(user);
                alert('Form submitted successfully!');
            }
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return (
        <>
            <Form id="updateUserData" onSubmit={handleSubmit(onSubmit)}>
                <h2>Dashboard details</h2>

                <TextInputFieldInline
                    name="firstName"
                    label="First Name"
                    type="text"
                    placeholder="John"
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.firstName}
                />

                <TextInputFieldInline
                    name="lastName"
                    label="Last Name"
                    type="text"
                    placeholder="Constantine"
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.lastName}
                />

                <TextInputFieldInline
                    name="neptunCode"
                    label="Neptun Code"
                    type="text"
                    placeholder="ABC123"
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.neptunCode}
                />

                <TextInputFieldInline
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="john.constantine@masterofthedarkarts.com"
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.email}
                />
                <Row className='mb-3'>
                    <Col className="col-3"><Form.Label >UserRole: </Form.Label></Col>
                    <Col className="col-6">
                        <Form.Group controlId={"userRole-input"}>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="dark" id="dropdown-basic" className='w-100'>
                                    {selectedRole ? selectedRole : "Choose a role"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item key="teacher" onClick={() => choosenDropdowItem("teacher")}>Teacher</Dropdown.Item>
                                    <Dropdown.Item key="admin" onClick={() => choosenDropdowItem("admin")}>Admin</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Button form="updateUserData" variant="dark" type="submit" className='w-100'>
                            Add User
                        </Button>
                    </Col>
                </Row>
            </Form >
        </>
    )
}

export default AddNewUserDashboard;
