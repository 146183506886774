import { Modal, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Course } from "../models/course";
import { CourseInput } from "../network/courses_api";
import * as CourseApi from "../network/courses_api";
import TextInputField from "./form/TextInputField";

interface AddEditCourseDialogProps {
    courseToEdit?: Course,
    onDismiss: () => void,
    onCourseSaved: (course: Course) => void,
}

const AddEditCourseDialog = ({ courseToEdit, onDismiss, onCourseSaved }: AddEditCourseDialogProps) => {

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<CourseInput>({
        defaultValues:{
            course_id: courseToEdit?.course_id || "",
            title: courseToEdit?.title || "",
            type: courseToEdit?.type || "",
        }
    });

    async function onSubmit(input: CourseInput) {
        try {
            let courseResponse: Course;
            if(courseToEdit){
                courseResponse = await CourseApi.updateCourse(courseToEdit._id, input);
            } else {
                courseResponse = await CourseApi.createCourse(input);
            }    
            onCourseSaved(courseResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return (
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {courseToEdit ? "Edit course" : "Add course"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="addEditCourseForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="course_id"
                        label="Course ID"
                        type="text"
                        placeholder="Course ID"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.course_id}
                    />
                    <TextInputField
                        name="title"
                        label="Title"
                        type="text"
                        placeholder="Course Title"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.title}
                    />
                    <Form.Group className="mb-3">
                        <Form.Label>Course Type</Form.Label>
                        <Form.Select aria-label="Select the course type" {...register("type", { required: "Required" })}>
                            <option value="Hungarian">Hungarian course</option>
                            <option value="English">English course</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="addEditCourseForm" disabled={isSubmitting}>{courseToEdit ? "Edit course" : "Add course"}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditCourseDialog;