import { Container } from "react-bootstrap";
import AssignmentsLoggedInView from "../components/AssignmentsLoggedInView";
import LoggedOutView from "../components/LoggedOutView";
import { User } from "../models/users";
import styleUtils from "../styles/utils.module.css";

interface AssignmentsPageProps {
    loggedInUser: User | null,
}

const AssignmentsPage = ({loggedInUser}: AssignmentsPageProps) => {
    return ( 
        <Container className={styleUtils.width100}>
				<>
					{loggedInUser
						? <AssignmentsLoggedInView />
						: <LoggedOutView />
					}
				</>
		</Container>
     );
}
 
export default AssignmentsPage;