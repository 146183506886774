import { Dropdown, Form } from "react-bootstrap";
import { Sections } from '../../models/sections';
import { useState } from "react";
import { Assignments as AssignmentModel } from "../../models/assignments";

interface DropdownSectionFieldProps {
    assignments: AssignmentModel[];
    name: string,
    [x: string]: any,
    onAssignmentSelected: (choosenAssignment: string[]) => void,
}

const DropdownAssignmentField = ({ assignments, name, onAssignmentSelected, ...props }: DropdownSectionFieldProps) => {
    const [selectedAssignment, setSelectedAssignment] = useState<AssignmentModel>();

    const choosenDropdowItem = (assignment:AssignmentModel) => {
        setSelectedAssignment(assignment);
        onAssignmentSelected(assignment.assignmentID);
    };

    return (
        <Form.Group className="mb-3" controlId={name + "-input"}>
            <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {selectedAssignment ? selectedAssignment.name : "Choose an assignment"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {assignments.map((assignment, index) => (
                        <Dropdown.Item key={index} onClick={() => choosenDropdowItem(assignment)}>{assignment.name}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
}

export default DropdownAssignmentField;