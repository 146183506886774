import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import * as AssignmentsApi from "../../network/assignments_api";
import "../../styles/customBootsrapOverride.css";
import TextInputFieldInlineForSmallTask from '../form/TextInputFieldForSmallTask';
import { Send, SendCheck, SendX } from 'react-bootstrap-icons';

interface SmallTaskResultsProps {
    courseID: string,
    assignmentID: String[],
    studentID: string,
    studentName: string,
    workflow_state: string,
    url: string,
    className?: string,
}

interface StudentData {
    id: number;
    name: string;
    posted_grade: number;
    nohandin: boolean;
    rubric_assessment: RubricAssessment;
}

interface RubricAssessment {
    [key: string]: {
        points: number;
        rating_id: string;
        comments: string;
        description: string;
    };
}

interface preparedRubricAssessment {
    [key: string]: {
        points: number;
        rating_id: string;
        comments: string;
    };
}

const SmallTaskResultsForStudent = ({ courseID, assignmentID, studentID, studentName, workflow_state, url, className }: SmallTaskResultsProps) => {
    const [ResultLoading, setResultLoading] = useState(true);
    const [showResultLoadingError, setResultLoadingError] = useState(false);

    const [posted_grade, setPosted_grade] = useState<number>(0);
    const [noHandIn, setNoHandIn] = useState<boolean>(false);
    const [rubric_assessment, setRubric_assessment] = useState<RubricAssessment>();

    const [resultSentToCanvas, setResultSentToCanvas] = useState<string>("unset");
    const [showAlertOK, setShowAlertOK] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const handleClose = () => {
        setShowAlertFail(false); 
        setShowAlertOK(false);
    }

    useEffect(() => {
        async function loadSmallTaskResult() {
            try {
                setResultLoadingError(false);
                setResultLoading(true);
                const jsonString = JSON.stringify({ id: studentID, name: studentName, workflow_state: workflow_state, url: url });
                const data = JSON.parse(jsonString);
                const result = await AssignmentsApi.fetchSmallTaskAssignemntForStudent(courseID, assignmentID.toString(), studentID, data);
                setPosted_grade(result.posted_grade);
                setNoHandIn(result.nohandin);
                setRubric_assessment(result.rubric_assessment);
            } catch (error) {
                console.error(error);
                setResultLoadingError(true);
            } finally {
                setResultLoading(false);
            }
        }
        loadSmallTaskResult();
    }, []);

    async function handleSubmit(formData: {
        rubric_assessment: any;
    }) {
        const { rubric_assessment } = formData;

        const rubricAssessment: { [key: string]: preparedRubricAssessment } = {};
        Object.keys(rubric_assessment).forEach((key) => {
            rubricAssessment[key] = {
                points: rubric_assessment[key].points,
                rating_id: rubric_assessment[key].rating_id,
                comments: rubric_assessment[key].comments,
            };
        });

        const jsonString = JSON.stringify({ rubric_assessment: rubricAssessment });
        const data = JSON.parse(jsonString);
        let response = await AssignmentsApi.udpateSmallTaskAssignemntResultForStudent(courseID, assignmentID.toString(), studentID, data);
        if (response.includes("200")) {
            setResultSentToCanvas("success");
            setShowAlertOK(true);
        } else {
            setResultSentToCanvas("failed");
            setShowAlertFail(true);
        }
    }

    const handlePosted_gradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosted_grade(Number(event.target.value));
    };

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const updatedAssesment = { ...rubric_assessment };
        if (Number(event.target.value) > rubric_assessment![key].points) {
            setPosted_grade(posted_grade + 1);
        } else {
            setPosted_grade(posted_grade - 1);
        }
        updatedAssesment[key] = {
            points: Number(event.target.value),
            rating_id: rubric_assessment![key].rating_id,
            comments: rubric_assessment![key].comments,
            description: rubric_assessment![key].description,
        };
        setRubric_assessment(updatedAssesment);

    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const updatedComment = { ...rubric_assessment };
        updatedComment[key] = {
            points: rubric_assessment![key].points,
            rating_id: rubric_assessment![key].rating_id,
            comments: event.target.value,
            description: rubric_assessment![key].description,
        };
        setRubric_assessment(updatedComment);
    };

    const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = { rubric_assessment };
        handleSubmit(formData);
    };


    return (
        <>
            {ResultLoading && <Spinner animation='border' variant='dark' />}
            {showResultLoadingError && <p>Something went wrong. Please refersh the page.</p>}
            {!ResultLoading &&
                <>
                    <Form id="postStudentResultToCanvas" onSubmit={handleSubmitForm}>
                        {showAlertFail && (
                            <Alert variant="danger" dismissible onClose={handleClose}>
                                Something went wrong! Result was NOT posted.
                            </Alert>
                        )}
                        {showAlertOK && (
                            <Alert variant="success" dismissible onClose={handleClose}>
                                Result is successfully posted on Canvas.
                            </Alert>
                        )}
                        <Row>
                            <Col className="col-11 mb-3">
                                <h1>{studentName}</h1>
                            </Col>
                            <Col className="col-1 mb-3">
                                <Button variant="dark" form="postStudentResultToCanvas" type="submit">
                                    {resultSentToCanvas === "success" ?
                                        (<SendCheck size={25} />)
                                        : resultSentToCanvas === "failed" ?
                                            (<SendX size={25} />)
                                            : (<Send size={25} />)
                                    }
                                </Button>
                            </Col>
                        </Row>
                        {(noHandIn === true) &&
                            <Alert variant="warning">Nem adott be feladat megoldást!</Alert>
                        }
                        <TextInputFieldInlineForSmallTask
                            name="posted_grade"
                            label="Final score"
                            type="number"
                            min="0"
                            max="8"
                            value={posted_grade}
                            onChange={handlePosted_gradeChange}
                            disabled
                            // register={register}
                            // error={errors.posted_grade}
                        />
                        {Object.keys(rubric_assessment!).map((key) => (
                            <Form.Group key={key} id={"error-" + key}>
                                <i><b><Form.Text key={{ key } + "-task"} className="fs-6">{rubric_assessment![key].description}</Form.Text></b></i>
                                <TextInputFieldInlineForSmallTask
                                    key={{ key } + "-score"}
                                    name="score"
                                    label="Score"
                                    type="number"
                                    min="0"
                                    max="1"
                                    value={rubric_assessment![key].points}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleScoreChange(event, key)}
                                // register={register}
                                // error={error.score}
                                />
                                <TextInputFieldInlineForSmallTask
                                    key={{ key } + "-msg"}
                                    name="msg"
                                    label="Message"
                                    type="text"
                                    value={rubric_assessment![key].comments}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleCommentChange(event, key)}
                                // register={register}
                                // error={error.score} 
                                />
                                <hr />
                            </Form.Group>
                        ))}
                    </Form>
                </>
            }
        </>
    );
}

export default SmallTaskResultsForStudent;