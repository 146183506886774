import { Col, Image, Row } from "react-bootstrap";
import styleUtils from "../styles/utils.module.css";
import styleHomePage from "../styles/homepage.module.css";

const HomePage = () => {
    return (
        <Row>
            <Col>
                <Image src="/tagCapture_dark.svg" className={styleUtils.blockCenter} alt="tagCapture logo"/>
                {/* <p>Validate the ELTE Web-development course's tasks with ease.</p> */}
            {/* </Col>
            <Col> */}
                <Image src="/img/programming.svg" className={`${styleUtils.blockCenter} ${styleHomePage.mainGraphics}`} />
            </Col>
        </Row>
    );
}

export default HomePage;