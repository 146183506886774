import { Container } from "react-bootstrap";
import DocumentationLoggedInView from "../components/DocumentationLoggedInView";
import LoggedOutView from "../components/LoggedOutView";
import { User } from "../models/users";
import styleUtils from "../styles/utils.module.css";

interface DocumentationPageProps {
    loggedInUser: User | null,
}

const DocumentationPage = ({loggedInUser}: DocumentationPageProps) => {
    return (
        <Container className={styleUtils.width100}>
				<>
					{loggedInUser
						? <DocumentationLoggedInView />
						: <LoggedOutView />
					}
				</>
		</Container>
    );
}

export default DocumentationPage;