import { useEffect, useState } from 'react';
import { Container, Spinner } from "react-bootstrap";
import { User as UserModel } from '../models/users';
import * as UserApi from "../network/users_api";
import Profile from './Profile';
import styleUtils from "../styles/utils.module.css";

const ProfileLoggedInView = () => {
    const [user, setUser] = useState<UserModel>();
    const [userLoading, setUserLoading] = useState(true);
    const [showUserLoadingError, setShowUserLoadingError] = useState(false);

    useEffect(() => {
        async function loadUser() {
            try {
                setShowUserLoadingError(false);
                setUserLoading(true);
                const user = await UserApi.fetchUser();
                setUser(user);
            } catch (error) {
                console.error(error);
                setShowUserLoadingError(true);
            } finally {
                setUserLoading(false);
            }
        }
        loadUser();
    }, []);


    return (
        <>
            {userLoading && <Spinner animation='border' variant='dark' />}
            {showUserLoadingError && <p>Something went wrong. Please refersh the page.</p>}
            <Container className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
                {user &&
                    <Profile 
                        user={user}
                        onProfileSaved={(user) => { 
                            setUser(user);
                        }} />
                }
            </Container>            
        </>
    );
}

export default ProfileLoggedInView;