import { Image } from "react-bootstrap";
import styleUtils from "../styles/utils.module.css";

const PageNotFound = () => {
    return ( 
        <div>
            <Image src="/img/page_not_found.svg" className={styleUtils.blockCenter}/>
            <br />
            <h1 className={styleUtils.textCenter}><i>Page not found :(</i></h1>
        </div>
     );
}
 
export default PageNotFound;