import { Image } from "react-bootstrap";
import styleUtils from "../styles/utils.module.css";

const LoggedOutView = () => {
    return (
        <>
            <h1 className={styleUtils.textCenter}>Main page</h1>
            <p className={styleUtils.textCenter}>You need to log in to use the app.</p>
            <Image src="./img/programming.svg" className={styleUtils.blockCenter} />
        </>
    );
}

export default LoggedOutView;