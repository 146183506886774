import { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { Students, Students as StudentsModel } from '../../models/students';
import * as AssignmentsApi from "../../network/assignments_api";
import DropdownStudentField from '../form/DropdownStudentField';

interface StudentProps {
    readonly courseID: string;
    readonly assignmentID: String[];
    readonly sectionID: string;
    onStudentChoosen: (student: Students) => void;
    onSectionChoosen: (students: StudentsModel[]) => void;
}

const Section = ({ courseID, assignmentID, sectionID, onStudentChoosen, onSectionChoosen }: StudentProps) => {
    const [students, setStudents] = useState<StudentsModel[]>([]);
    const [studentLoading, setStudentLoading] = useState(true);
    const [showStudentLoadingError, setShowStudentLoadingError] = useState(false);

    async function passStudentBack(studentSelected:Students) {
        onStudentChoosen(studentSelected);
    }

    useEffect(() => {
        async function loadStudents() {
            try {
                setShowStudentLoadingError(false);
                setStudentLoading(true);
                const student = await AssignmentsApi.fetchStudents(courseID, assignmentID.toString(), sectionID);
                setStudents(student);
                onSectionChoosen(student);
            } catch (error) {
                console.error(error);
                setShowStudentLoadingError(true);
            } finally {
                setStudentLoading(false);
            }
        }
        loadStudents();
    }, [courseID, assignmentID, sectionID]);

    return (
        <>
            {studentLoading && <Spinner animation='border' variant='dark' />}
            {showStudentLoadingError && <p>Something went wrong. Please refersh the page.</p>}
            {!studentLoading &&
                <>
                    <DropdownStudentField
                        students={students}
                        name="Students"
                        type="dropdown"
                        onStudentSelected={(choosenStudent) => {
                            passStudentBack(choosenStudent);
                        }}
                    />
                </>
            }
        </>
    );
}

export default Section;