import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import * as AssignmentsApi from "../../network/assignments_api";
import "../../styles/customBootsrapOverride.css";
import TextInputFieldInlineForSmallTask from '../form/TextInputFieldForSmallTask';
import { Students } from '../../models/students';
import { CaretLeft, CaretRight, Send, SendCheck, SendX } from 'react-bootstrap-icons';
import { SmalltaskSectionResultSubmission } from '../../models/smalltaskSectionResultSubmission';

interface SmallTaskResultsProps {
    courseID: string,
    sectionID: string,
    assignmentID: String[],
    studentsList: Students[],
    className?: string,
}

interface StudentData {
    id: number;
    name: string;
    posted_grade: number;
    nohandin: boolean;
    workflow_state: string;
    rubric_assessment: RubricAssessment;
}

interface RubricAssessment {
    [key: string]: {
        points: number;
        rating_id: string;
        comments: string;
        description: string;
    };
}

interface prepareRubricAssessment {
    rubric_assessment: {
        [key: string]: {
            points: number;
            rating_id: string;
            comments: string;
        };
    }
}

const SmallTaskResultsForSection = ({ courseID, sectionID, assignmentID, studentsList, className }: SmallTaskResultsProps) => {
    const [ResultLoading, setResultLoading] = useState(true);
    const [showResultLoadingError, setResultLoadingError] = useState(false);

    // list of the students results
    const [students, setStudents] = useState<StudentData[]>([]);

    // current sudent's data
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [student, setStudent] = useState<StudentData>(students[currentIndex]);

    const [resultSentToCanvas, setResultSentToCanvas] = useState<string>("unset");
    const [showAlertOK, setShowAlertOK] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [showAlertNothingToPost, setShowAlertNothingToPost] = useState(false);

    const handleClose = () => {
        setShowAlertFail(false);
        setShowAlertOK(false);
        setShowAlertNothingToPost(false);
    }

    const handleNext = () => {
        setStudents(prevStudents => {
            const updatedStudents = [...prevStudents];
            updatedStudents[currentIndex] = student;
            return updatedStudents;
        });
        if (currentIndex < students.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setStudent(students[currentIndex + 1]);
        }
    };

    const handlePrev = () => {
        setStudents(prevStudents => {
            const updatedStudents = [...prevStudents];
            updatedStudents[currentIndex] = student;
            return updatedStudents;
        });
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setStudent(students[currentIndex - 1]);
        }
    };

    useEffect(() => {
        async function loadSmallTaskResult() {
            try {
                setResultLoadingError(false);
                setResultLoading(true);
                const jsonString = JSON.stringify({ students: studentsList });
                const data = JSON.parse(jsonString);
                const result = await AssignmentsApi.fetchSmallTaskAssignemntForSection(courseID, assignmentID.toString(), data);
                setStudents(result);
                setStudent(result[0]);
                setShowAlertNothingToPost(false);
            } catch (error) {
                console.error(error);
                setResultLoadingError(true);
            } finally {
                setResultLoading(false);
            }
        }
        loadSmallTaskResult();
    }, []);

    async function handleSubmit() {
        let isAllGraded = true;
        const listOfStudents: { [key: string]: prepareRubricAssessment } = {};
        students.map((student) => {
            const smalltaskSectionResultSubmission: prepareRubricAssessment = {
                rubric_assessment: {},
            };

            if (student.workflow_state !== "graded") {
                Object.keys(student.rubric_assessment).forEach((key) => {
                    smalltaskSectionResultSubmission.rubric_assessment[key] = {
                        points: student.rubric_assessment[key].points,
                        rating_id: student.rubric_assessment[key].rating_id,
                        comments: student.rubric_assessment[key].comments,
                    };

                });

                listOfStudents[student.id] = smalltaskSectionResultSubmission;
                isAllGraded = false;
            }
        });

        const jsonString = JSON.stringify({ grade_data: listOfStudents });
        const data: SmalltaskSectionResultSubmission = JSON.parse(jsonString);

        if (!isAllGraded) {
            let response = await AssignmentsApi.udpateSmallTaskAssignemntResultForSection(sectionID, assignmentID.toString(), data);
            if (response.includes("200")) {
                setResultSentToCanvas("success");
                setShowAlertOK(true);
                setShowAlertNothingToPost(false);
            } else {
                setResultSentToCanvas("failed");
                setShowAlertFail(true);
            }
        } else {
            setShowAlertNothingToPost(true);
            setResultSentToCanvas("failed");
        }
    }

    const handlePosted_gradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        student.posted_grade = (Number(event.target.value));
    };

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        let grade: number;
        if (Number(event.target.value) > student.rubric_assessment![key].points) {
            grade = (student.posted_grade + 1);
        } else {
            grade = (student.posted_grade - 1);
        }

        setStudent(prevValues => ({
            ...prevValues,
            posted_grade: grade,
            rubric_assessment: {
                ...prevValues.rubric_assessment,
                [key]: {
                    ...prevValues.rubric_assessment[key],
                    points: Number(event.target.value)
                }
            }
        }));
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        setStudent(prevValues => ({
            ...prevValues,
            rubric_assessment: {
                ...prevValues.rubric_assessment,
                [key]: {
                    ...prevValues.rubric_assessment[key],
                    comments: event.target.value
                }
            }
        }));
    };

    const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setStudents(prevStudents => {
            const updatedStudents = [...prevStudents];
            updatedStudents[currentIndex] = student;
            return updatedStudents;
        });
        handleSubmit();
    };

    return (
        <>  {ResultLoading && <Spinner animation='border' variant='dark' />}
            {showResultLoadingError && <p>Something went wrong. Please refersh the page.</p>}
            {!ResultLoading &&
                <>
                    <Form id="postStudentResultToCanvas" onSubmit={handleSubmitForm}>
                        {showAlertFail && (
                            <Alert variant="danger" dismissible onClose={handleClose}>
                                Something went wrong! Result was NOT posted.
                            </Alert>
                        )}
                        {showAlertOK && (
                            <Alert variant="success" dismissible onClose={handleClose}>
                                Result is successfully posted on Canvas.
                            </Alert>
                        )}
                        {showAlertNothingToPost && (
                            <Alert variant="warning" dismissible onClose={handleClose}>
                                All the data in this section have already been graded.
                                No data was uploaded, if you want to override a student's grade use the individual grading option.
                            </Alert>
                        )}
                        <Row>
                            <Col className="col-11 mb-3">
                                <h1>{student.name}</h1>
                            </Col>
                            <Col className="col-1 mb-3">
                                <Button variant="dark" form="postStudentResultToCanvas" type="submit">
                                    {resultSentToCanvas === "success" ?
                                        (<SendCheck size={25} />)
                                        : resultSentToCanvas === "failed" ?
                                            (<SendX size={25} />)
                                            : (<Send size={25} />)
                                    }
                                </Button>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                {student.nohandin === true
                                    ? (<Alert variant="warning">Nem adott be feladat megoldást!</Alert>)
                                    : <>{student.nohandin}</>
                                }
                            </Col>
                        </Row> */}
                        <Row>
                            <Col></Col>
                            <Col className='col-2 offset-1 mb-3'><Button className="d-flex align-items-center" onClick={handlePrev}><CaretLeft size={25} /></Button></Col>
                            <Col className='col-3 mb-3 d-flex align-items-center'>{"Student: " + (currentIndex + 1) + " / " + students.length}</Col>
                            <Col className='col-2 mb-3'><Button className="d-flex align-items-center" onClick={handleNext}><CaretRight size={25} /></Button></Col>
                            <Col></Col>
                        </Row>
                        <TextInputFieldInlineForSmallTask
                            name="posted_grade"
                            label="Final score"
                            type="number"
                            min="0"
                            max="1"
                            value={student.posted_grade}
                            onChange={handlePosted_gradeChange}
                            disabled
                        // register={register}
                        // error={errors.posted_grade}
                        />
                        {Object.keys(student.rubric_assessment!).map((key) => (
                            <Form.Group key={key} id={"error-" + key}>
                                <i><b><Form.Text key={{ key } + "-task"} className="fs-6">{student.rubric_assessment![key].description}</Form.Text></b></i>
                                <TextInputFieldInlineForSmallTask
                                    key={{ key } + "-score"}
                                    name="score"
                                    label="Score"
                                    type="number"
                                    min="0"
                                    max="1"
                                    value={student.rubric_assessment![key].points}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleScoreChange(event, key)}
                                // register={register}
                                // error={error.score}
                                />
                                <TextInputFieldInlineForSmallTask
                                    key={{ key } + "-msg"}
                                    name="msg"
                                    label="Message"
                                    type="text"
                                    value={student.rubric_assessment![key].comments}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleCommentChange(event, key)}
                                // register={register}
                                // error={error.score} 
                                />
                                <hr />
                            </Form.Group>
                        ))}
                    </Form>
                </>
            }
        </>
    );
}

export default SmallTaskResultsForSection;