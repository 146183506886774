import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

const DocumentationLoggedInView = () => {
    return (
        <>
            <div>
            <Alert variant="warning">The validator is still in development, it might have some very special cases that still needs to be checked and corrected manually!</Alert>

                <h1>Known Bugs and how to solve them!</h1>
                <ul>
                    <li>On the  <Link to="/profile">Profile page</Link> the changing of the profile picture is not implemented fully yet.</li>
                    <ul>
                        <li><u>No solution:</u> <i>This feature will be implemented later, check back later.</i></li>
                        <li><u>Alternative:</u> <i>If you find one that you like, write me in MS Teams, I can change it in the database manually for now.</i></li>
                    </ul>
                </ul>
                <b className="text-danger">This App is still under development, if you find any other bugs, please let me know in MS Teams or email: <a href="mailto:vincze.dorottya@inf.elte.hu">vincze.dorottya@inf.elte.hu</a></b>
                <hr/>
                <h1>How to use the app?</h1>
                <h2>First steps</h2>
                <Alert variant="warning">Change your password from the generic to a custom in the profile section, that you can find if you click on your name!</Alert>
                <h3>How to get my Access Token key?</h3>
                <ol>
                    <li>Go to Canvas and login with your official canvas.elte account, than go to the settings page or follow <a href="https://canvas.elte.hu/profile/settings">this link to the settings page and login.</a></li>
                    <li>Find the <code>+ New Access Token</code> button and click it.</li>
                    <li>
                        <div>In the pop up window's purpose field give a name to your token and click the Generate Token button.</div>
                        <img src="/img/documentation/NewAccessTokenPopUp.png" alt="nameing our access token" />
                    </li>
                    <li>
                        <div>In the Access Token Details pop up window copy the text next to the Token title.</div>
                        <img src="/img/documentation/AccessTokenDetailsPopUp.png" alt="coping our access token" />
                    </li>
                    <li>In the web1 validator application go to your <Link to="/profile">Profile</Link> and paste your access token into the Access Token field and update your profile.</li>
                </ol>
                <h3>How to add courses?</h3>
                <ul>
                    <li>Go to the <Link to="/courses">Course</Link> page.</li>
                    <li>Click the box with the + sign.</li>
                    <li>In the pop up give a name to your course. </li>
                    <li>Add the <code>courseID</code>.</li>
                    <ul>
                        <li>You can find this in the canvas url of the web development course eg.: <code>https://canvas.elte.hu/courses/33775</code></li>
                        <li>In the example url the courseID is the number after the "/" which is <code>33775</code></li>
                    </ul>
                    <li>If necessary change the type of the course to English.</li>
                    <li>Click the "<code>Add course</code>" button.</li>
                </ul>
                <h3>How to validate the <i>Modulzáró gyakorló feladat (HTML5)?</i></h3>
                <ul>
                    <li>Click the course you previously added.</li>
                    <li>Choose the assignment type you want to validate.</li>
                    <li>Choose a section.</li>
                    <li>Choose a student.</li>
                    <li>Click the "<code>Validate</code>" button.</li>
                    <li>If you want to add your own comments or override the score chnage the values.</li>
                    <li>Click the "<code>Upload to Canvas</code>" button to post the results in Canvas</li>
                    <li><u>Upcoming updates:</u></li>
                    <ul>
                        <li>You will have the option to validate every student's assignemnt in the section and upload them in bulk. </li>
                    </ul>
                </ul>
            </div>
        </>
    );
}

export default DocumentationLoggedInView;