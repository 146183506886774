import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { Assignments } from "../models/assignments";
import { Sections } from "../models/sections";
import { Students } from "../models/students";
import { SmallTaskResult } from "../models/smalltasks";
import { PostSmallTaskResult } from "../models/smalltasks_results";
import { SmalltaskSectionResultSubmission } from "../models/smalltaskSectionResultSubmission";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);
    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        if (response.status === 401) {
            throw new UnauthorizedError(errorMessage);
        } else if (response.status === 409) {
            throw new ConflictError(errorMessage);
        } else {
            throw Error("Request falied with status: " + response.status + " message: " + errorMessage);
        }
    }
}

export async function fetchAssignment(courseID: string): Promise<Assignments[]> {
    const response = await fetchData(`/api/courses/${courseID}/assignments`, { method: "GET" });
    return response.json();
}

export async function fetchSections(courseID: string, assignmentID: string): Promise<Sections[]> {
    const response = await fetchData(`/api/courses/${courseID}/assignments/${assignmentID}`, { method: "GET" });
    return response.json();
}

export async function fetchStudents(courseID: string, assignmentID: string, sectionID: string): Promise<Students[]> {
    const response = await fetchData(`/api/courses/${courseID}/assignments/${assignmentID}/sections/${sectionID}/students`, { method: "GET" });
    return response.json();
}

export async function fetchSmallTaskAssignemntForStudent(courseID: string, assignmentID: string, userID: string, reqBody: string): Promise<SmallTaskResult> {
    const response = await fetchData(`/api/courses/${courseID}/assignments/${assignmentID}/submissions/${userID}/validate/smallProjectForStudent`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody),
        });
        return response.json();
}

export async function udpateSmallTaskAssignemntResultForStudent(courseID: string, assignmentID: string, userID: string, studentResult: PostSmallTaskResult): Promise<any> {
    const response = await fetchData(`/api/courses/${courseID}/assignments/${assignmentID}/submissions/${userID}/validated/postToCanvas`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(studentResult),
        });
        return response.json();
}


interface StudentData {
    id: number;
    name: string;
    posted_grade: number;
    nohandin: boolean;
    workflow_state: string,
    rubric_assessment: RubricAssessment;
}

interface RubricAssessment {
    [key: string]: {
        points: number;
        rating_id: string;
        comments: string;
        description: string;
    };
}

export async function fetchSmallTaskAssignemntForSection(courseID: string, assignmentID: string, reqBody: any): Promise<StudentData[]> {
    const response = await fetchData(`/api/courses/${courseID}/assignments/${assignmentID}/submissions/validate/smallProjectForSection`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody),
        });
        return response.json();
}

export async function udpateSmallTaskAssignemntResultForSection(sectionID: string, assignmentID: string, studentsResult: SmalltaskSectionResultSubmission): Promise<any> {
    const response = await fetchData(`/api/sections/${sectionID}/assignments/${assignmentID}/submissions/validated/sectionToCanvas`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(studentsResult),
        });
        return response.json();
}