import { Button, Image, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { User } from "../models/users";
import * as UsersApi from "../network/users_api";
import styleNavBar from "../styles/navBar.module.css";

interface NavBarLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const NavBarLoggedInView = ({ user, onLogoutSuccessful }: NavBarLoggedInProps) => {
    async function logout() {
        try {
            await UsersApi.logout();
            onLogoutSuccessful();
        } catch (error) {
            alert(error);
            console.error(error);
        }
    }

    return (
        <>
            <Image src={`/profilePics/${user.profilePic}`} className={`ms-auto ${styleNavBar.navImg}`}></Image>
            <Nav className="ms-auto">
                <Nav.Link as={Link} to="/profile" className={styleNavBar.space}>
                    {user.lastName + " " + user.firstName}
                    {/* {user.firstName} */}
                </Nav.Link>
                <Button onClick={logout} >Log out</Button>
            </Nav>
        </>
    );
}

export default NavBarLoggedInView;