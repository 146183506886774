import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

interface NavBarisAdminProps {
    isAdmin: boolean,
}

const NavBarLoggedInMainView = ({ isAdmin }: NavBarisAdminProps) => {
    return (
        <>
            <Nav.Link as={Link} to="/courses">Courses</Nav.Link>
            {isAdmin &&
                <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
            }
            <Nav.Link as={Link} to="/documentation">Documentation</Nav.Link>
        </>
    );
}

export default NavBarLoggedInMainView;