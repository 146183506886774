import { Col, Row, Form } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";

interface TextInputFieldInlineProps {
    name: string,
    label: string,
    register: UseFormRegister<any>,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    [x: string]: any,
}

const TextInputFieldInline = ({ name, label, register, registerOptions, error, ...props }: TextInputFieldInlineProps) => {
    return (
        <Form.Group as={Row} className="mb-2" controlId={name + "-input"}>
            <Form.Label column sm={3}>{`${label}:`}</Form.Label>
            <Col sm={9}>
                <Form.Control
                    {...props}
                    {...register(name, registerOptions)}
                    isInvalid={!!error}
                />
                <Form.Control.Feedback type="invalid">
                    {error?.message}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export default TextInputFieldInline;