import { Container } from "react-bootstrap";
import ProfileLoggedInView from "../components/ProfileLoggedInView";
import LoggedOutView from "../components/LoggedOutView";
import { User } from "../models/users";
import styleUtils from "../styles/utils.module.css";

interface ProfilePageProps {
    loggedInUser: User | null,
}

const ProfilePage = ({loggedInUser}: ProfilePageProps) => {
    return ( 
        <Container className={styleUtils.width100}>
				<>
					{loggedInUser
						? <ProfileLoggedInView />
						: <LoggedOutView />
					}
				</>
		</Container>
     );
}
 
export default ProfilePage;