import { Container } from "react-bootstrap";
import CoursesLoggedInView from "../components/CoursesLoggedInView";
import LoggedOutView from "../components/LoggedOutView";
import { User } from "../models/users";
import styles from "../styles/CoursePage.module.css";

interface CoursesPageProps {
    loggedInUser: User | null,
}

const CoursesPage = ({loggedInUser}: CoursesPageProps) => {
    return ( 
        <Container className={styles.coursesPage}>
				<>
					{loggedInUser
						? <CoursesLoggedInView />
						: <LoggedOutView />
					}
				</>
			</Container>
     );
}
 
export default CoursesPage;