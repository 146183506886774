import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { User } from "../models/users";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);
    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        if (response.status === 401){
            throw new UnauthorizedError(errorMessage);
        } else if (response.status === 409){
            throw new ConflictError(errorMessage);
        } else {
            throw Error("Request falied with status: " + response.status + " message: " + errorMessage);
        }
        // throw Error(errorMessage);
    }
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData("/api/users", { method: "GET" });
    return response.json();
}

export async function fetchUser(): Promise<User> {
    const response = await fetchData("/api/users/profile", { method: "GET" });
    return response.json();
}

export async function fetchUsersDashboard(): Promise<User[]> {
    const response = await fetchData("/api/users/dashboard", { method: "GET" });
    return response.json();
}

export interface AdminPageCredantials {
    firstName: string,
    lastName: string,
    neptunCode: string,
    email: string,
    userRole: string,
}

export async function addUser(credentials: AdminPageCredantials): Promise<User> {
    const response = await fetchData("/api/users/dashboard",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export async function udpateUserFromDashboard(credentials: AdminPageCredantials): Promise<User> {
    const response = await fetchData("/api/users/dashboard",
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface ProfilePageCredantials {
    email: string,
    password: string,
    profilePic: string,
    sharedSecret: string,
}

export async function udpateUser(credentials: ProfilePageCredantials): Promise<User> {
    const response = await fetchData("/api/users/profile",
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface LoginCredantials {
    neptunCode: string,
    password: string,
}

export async function login(credentials: LoginCredantials): Promise<User> {
    const response = await fetchData("/api/users/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    });
    return response.json();
}

export async function logout(){
    await fetchData("/api/users/logout", { method: "POST" });
}

export async function deletUser(userID: string) {
    await fetchData("api/users/dashboard/" + userID, { method: "DELETE" });
}
