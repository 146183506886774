import { Dropdown, Form } from "react-bootstrap";
import { Sections } from '../../models/sections';
import { useState } from "react";

interface DropdownSectionFieldProps {
    sections: Sections[];
    name: string,
    [x: string]: any,
    onSectionSelected: (choosenSection: string) => void,
}

const DropdownSectionField = ({ sections, name, onSectionSelected, ...props }: DropdownSectionFieldProps) => {
    const [selectedSection, setSelectedSection] = useState<Sections>();

    const choosenDropdowItem = (section:Sections) => {
        setSelectedSection(section);
        onSectionSelected(section.sectionID);
    };

    return (
        <Form.Group className="mb-3" controlId={name + "-input"}>
            <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {selectedSection ? selectedSection.name : "Choose a section"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {sections.map(section => (
                        <Dropdown.Item key={section.sectionID} onClick={() => choosenDropdowItem(section)}>{section.name}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
}

export default DropdownSectionField;