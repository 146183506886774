import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import LoginModal from './components/LoginModal';
import NavBar from './components/NavBar';
import { User } from "./models/users";
import * as UserApi from "./network/users_api";
import AssignmentsPage from './pages/AssignmentsPage';
import CoursesPage from './pages/CoursesPage';
import DashboardPage from './pages/DashboardPage';
import DocumentationPage from './pages/DocumentationPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/NotFoundPage';
import ProfilePage from './pages/ProfilePage';


function App() {
	const [isAdmin, setIsAdmin] = useState<true | false>(false);
	const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
	const [showLoginModal, setShowLoginModal] = useState(false);

	async function fetchLoggedInUser() {
		try {
			const user = await UserApi.getLoggedInUser();
			setIsAdmin(user.userRole === "admin");
			setLoggedInUser(user);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchLoggedInUser();
	}, []);

	return (
		<BrowserRouter>
			<div>
				<NavBar
					loggedInUser={loggedInUser}
					isAdmin={isAdmin}
					onLoginClicked={() => setShowLoginModal(true)}
					onLogoutSuccessful={() => {
						setLoggedInUser(null);
						setIsAdmin(false);
					}}
				/>
				<Container>
					<Routes>
						<Route
							path='/'
							element={<HomePage />}
						/>
						<Route
							path='/courses/:courseID/assignments/:assignmentID/sections/:sectionID/students'
							element={<AssignmentsPage loggedInUser={loggedInUser} />}
						/>
						<Route
							path='/courses/:courseID/assignments/:assignmentID'
							element={<AssignmentsPage loggedInUser={loggedInUser} />}
						/>
						<Route
							path='/courses/:courseID/assignments'
							element={<AssignmentsPage loggedInUser={loggedInUser} />}
						/>
						<Route
							path='/courses'
							element={<CoursesPage loggedInUser={loggedInUser} />}
						/>
						<Route
							path='/dashboard'
							element={<DashboardPage loggedInUser={loggedInUser} isAdmin={isAdmin} />}
						/>
						<Route
							path='/documentation'
							element={<DocumentationPage loggedInUser={loggedInUser} />}
						/>
						<Route
							path='/profile'
							element={<ProfilePage loggedInUser={loggedInUser} />}
						/>
						<Route
							path='/*'
							element={<PageNotFound />}
						/>
					</Routes>
				</Container>
				{showLoginModal &&
					<LoginModal
						onDismiss={() => setShowLoginModal(false)}
						onLoginSuccessfull={(user) => {
							setLoggedInUser(user);
							fetchLoggedInUser();
							setShowLoginModal(false);
						}}
					/>
				}
			</div>
		</BrowserRouter >
	);
}

export default App;
