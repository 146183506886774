import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { User } from "../models/users";
import styleNavBar from "../styles/navBar.module.css";
import NavBarLoggedInMainView from "./NavBarLoggedInMainView";
import NavBarLoggedInView from "./NavBarLoggedInView";
import NavBarLoggedOutView from "./NavBarLoggedOutView";

interface NavBarProps {
    loggedInUser: User | null,
    isAdmin: boolean,
    onLoginClicked: () => void,
    onLogoutSuccessful: () => void,
}

const NavBar = ({ loggedInUser, isAdmin, onLoginClicked, onLogoutSuccessful }: NavBarProps) => {
    return (
        <Navbar bg="dark" variant="dark" expand="sm" sticky="top" className={styleNavBar.navMargin}>
            <Container>
                <Link to="/">
                    <Image src="/tagCapture_white.svg" className={styleNavBar.logo} alt="tagCapture logo" />
                </Link>
                <Navbar.Toggle aria-controls="main-navbar" />
                <Navbar.Collapse id="main-navbar">
                    <Nav>
                        {loggedInUser &&
                            <NavBarLoggedInMainView isAdmin={isAdmin} />
                        }
                    </Nav>
                    <Nav className="ms-auto">
                        {loggedInUser
                            ? <NavBarLoggedInView user={loggedInUser} onLogoutSuccessful={onLogoutSuccessful} />
                            : <NavBarLoggedOutView onLoginClicked={onLoginClicked} />
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;