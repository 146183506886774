import { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from 'react-icons/fa';
import { Course as CourseModel } from '../models/course';
import * as CourseApi from "../network/courses_api";
import styles from "../styles/CoursePage.module.css";
import styleUtils from "../styles/utils.module.css";
import AddEditCourseDialog from "./AddEditCourseDialog";
import Course from './Courses';

const CoursesLoggedInView = () => {
    const [courses, setCourses] = useState<CourseModel[]>([]);
    const [coursesLoading, setCourseLoading] = useState(true);
    const [showCourseLoadingError, setShowCourseLoadingError] = useState(false);

    const [showAddCourseDialog, setShowAddCourseDialog] = useState(false);
    const [courseToEdit, setCourseToEdit] = useState<CourseModel | null>(null);

    useEffect(() => {
        async function loadCourses() {
            try {
                setShowCourseLoadingError(false);
                setCourseLoading(true);
                const courses = await CourseApi.fetchCourse();
                setCourses(courses);
            } catch (error) {
                console.error(error);
                setShowCourseLoadingError(true);
            } finally {
                setCourseLoading(false);
            }
        }
        loadCourses();
    }, []);

    async function deleteCourse(course: CourseModel) {
        try {
            await CourseApi.deletCourse(course._id);
            setCourses(courses.filter(existingCourse => existingCourse._id !== course._id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    const addCourse =
        <Card className={`${styles.fakeCourseCard} ${styles.course}`} onClick={() => setShowAddCourseDialog(true)}>
            <Card.Body className={`${styles.fakeCourseCardBtn} ${styleUtils.blockCenter} ${styleUtils.flexCenter}`}>
                <FaPlus />
            </Card.Body>
        </Card>

    const courseGrid =
        <Row xs={1} md={3} xl={6} className={`g-4 ${styles.coursesGrid}`}>
            <Col>
                <Card className={`${styles.fakeCourseCard} ${styles.course}`} onClick={() => setShowAddCourseDialog(true)}>
                    <Card.Body className={`${styles.fakeCourseCardBtn} ${styleUtils.blockCenter} ${styleUtils.flexCenter}`}>
                        <FaPlus />
                    </Card.Body>
                </Card>
            </Col>
            {courses.map(course => (
                <Col key={course._id}>
                    <Course
                        course={course}
                        className={styles.course}
                        onEditCourseClicked={setCourseToEdit}
                        onDeleteCourseClicked={deleteCourse}
                    />
                </Col>
            ))}
        </Row>

    return (
        <>
            {coursesLoading && <Spinner animation='border' variant='primary' />}
            {showCourseLoadingError && <p>Something went wrong. Please refersh the page.</p>}
            {!coursesLoading && !showCourseLoadingError &&
                <>
                    {courses.length > 0
                        ? courseGrid
                        : addCourse
                    }
                </>
            }
            {showAddCourseDialog &&
                <AddEditCourseDialog
                    onDismiss={() => setShowAddCourseDialog(false)}
                    onCourseSaved={(newCourse) => {
                        setCourses([...courses, newCourse])
                        setShowAddCourseDialog(false);
                    }}
                />
            }
            {courseToEdit &&
                <AddEditCourseDialog
                    courseToEdit={courseToEdit}
                    onDismiss={() => setCourseToEdit(null)}
                    onCourseSaved={(updatedCourse) => {
                        setCourses(courses.map(existingCourse => existingCourse._id === updatedCourse._id ? updatedCourse : existingCourse))
                        setCourseToEdit(null);
                    }}
                />
            }
        </>
    );
}

export default CoursesLoggedInView;