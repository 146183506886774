import { Container } from "react-bootstrap";
import DashboardLoggedInView from "../components/adminpanel/DashboardLoggedInView";
import LoggedOutView from "../components/LoggedOutView";
import { User } from "../models/users";
import styleUtils from "../styles/utils.module.css";

interface DashboardPageProps {
	loggedInUser: User | null,
	isAdmin: boolean,
}

const DashboardPage = ({ loggedInUser, isAdmin }: DashboardPageProps) => {
	return (
		<Container className={styleUtils.width100}>
			<>
				{(loggedInUser && isAdmin)
					? <DashboardLoggedInView />
					: <LoggedOutView />
				}
			</>
		</Container>
	);
}

export default DashboardPage;