import { Dropdown, Form } from "react-bootstrap";
import { Students } from '../../models/students';
import { useState } from "react";
import { CheckCircleFill, CircleFill, XCircleFill } from 'react-bootstrap-icons';
import sytleStudentDropdown from "../../styles/studentDropdown.module.css";

interface DropdownStudentFieldProps {
    students: Students[];
    name: string,
    [x: string]: any,
    onStudentSelected: (choosenStudent: Students) => void,
}

const DropdownStudentField = ({ students, name, onStudentSelected, ...props }: DropdownStudentFieldProps) => {
    const [selectedStudent, setSelectedStudent] = useState<Students>();

    const choosenDropdowItem = (student: Students) => {
        setSelectedStudent(student);
        onStudentSelected(student);
    };
    return (
        <Form.Group className="mb-3" controlId={name + "-input"}>
            <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {selectedStudent ? selectedStudent.name : "Choose a student"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {students.map(student => (
                        <Dropdown.Item key={student.name} onClick={() => choosenDropdowItem(student)} className="d-flex align-items-center">
                            {student.workflow_state === "graded" ? (
                                <><CheckCircleFill size={13} className={`text-success ${sytleStudentDropdown.space_right}`} />{student.name}</>
                            ) : student.workflow_state === "submitted" ? (
                                <><CircleFill className={`text-warning ${sytleStudentDropdown.space_right}`} size={13} />{student.name}</>
                            ) : student.workflow_state === "unsubmitted" ? (
                                <><XCircleFill className={`text-danger ${sytleStudentDropdown.space_right}`} size={13} />{student.name}</>
                            ) : (
                                <>{student.name}</>
                            )}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
}

export default DropdownStudentField;