import { useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { User as UserModel } from "../../models/users";
import * as UserApi from "../../network/users_api";
import { AdminPageCredantials } from '../../network/users_api';
import "../../styles/customBootsrapOverride.css";
import TextInputFieldInline from '../form/TextInputFieldInline';

interface DashboardProps {
    user: UserModel,
    // onUserSaved: (user: User) => void,
    className?: string,
}

const UpdateUserDashboard = ({ user, className }: DashboardProps) => {
    const [selectedRole, setSelectedRole] = useState<string>(user.userRole);
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            neptunCode: user?.neptunCode || "",
            email: user?.email || "",
            userRole: user?.userRole || selectedRole || "",
        }
    });
    
    const choosenDropdowItem = (role: string) => {
        setSelectedRole(role);
    };

    async function onSubmit(data: AdminPageCredantials) {
        try {
            await UserApi.udpateUserFromDashboard(data);
            alert('Form submitted successfully!');
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return (
        <>
            <Form id="updateUserData" onSubmit={handleSubmit(onSubmit)}>
                <TextInputFieldInline
                    name="firstName"
                    label="First Name"
                    type="text"
                    placeholder={user.firstName}
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.firstName}
                />

                <TextInputFieldInline
                    name="lastName"
                    label="Last Name"
                    type="text"
                    placeholder={user.lastName}
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.lastName}
                />

                <TextInputFieldInline
                    name="neptunCode"
                    label="Neptun Code"
                    type="text"
                    placeholder={user.neptunCode}
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.neptunCode}
                />

                <TextInputFieldInline
                    name="email"
                    label="Email"
                    type="email"
                    placeholder={user.firstName}
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.email}
                />
                <Row className='mb-3'>
                    <Col className="col-3"><Form.Label >UserRole: </Form.Label></Col>
                    <Col className="col-6">
                        <Form.Group controlId={"userRole-input"}>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="dark" id="dropdown-basic" className='w-100'>
                                    {selectedRole}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item key="teacher" onClick={() => choosenDropdowItem("teacher")}>Teacher</Dropdown.Item>
                                    <Dropdown.Item key="admin" onClick={() => choosenDropdowItem("admin")}>Admin</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Button form="updateUserData" variant="dark" type="submit" className='w-100'>
                            Update User
                        </Button>
                    </Col>
                </Row>
            </Form >
        </>
    )
}

export default UpdateUserDashboard;