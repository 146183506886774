import { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { PersonAdd } from 'react-bootstrap-icons';
import { User as UserModel } from '../../models/users';
import * as UserApi from "../../network/users_api";
import styles from "../../styles/CoursePage.module.css";
import DropdownUsersField from '../form/DropdownUsersField';
import AddNewUserDashboard from './AddNewUserDashboard';
import UpdateUserDashboard from './UpdateUserDashboard';

const DashboardLoggedInView = () => {
    const [users, setUsers] = useState<UserModel[]>([]);
    const [usersLoading, setUsersLoading] = useState(true);
    const [showUsersLoadingError, setShowUsersLoadingError] = useState(false);

    const [addNewUserVisible, setAddNewUserVisible] = useState(false);
    const [existingUserVisible, setExistingUserVisible] = useState(false);
    const [user, setUser] = useState<UserModel>();
    const [userLoading, setUserLoading] = useState(false);
    const [showUserLoadingError, setShowUserLoadingError] = useState(false);

    const [deleteUserVisible, setDeleteUserVisible] = useState(false);

    useEffect(() => {
        async function loadUsers() {
            try {
                setShowUsersLoadingError(false);
                setUsersLoading(true);
                const users = await UserApi.fetchUsersDashboard();
                setUsers(users);
            } catch (error) {
                console.error(error);
                setShowUsersLoadingError(true);
            } finally {
                setUsersLoading(false);
            }
        }
        loadUsers();
    }, []);

    async function deleteUser(user: UserModel) {
        try {
            await UserApi.deletUser(user._id);
            setUsers(users.filter(existingUser => existingUser._id !== user._id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return (
        <>
            <Row xs={12} md={12} xl={12} className={`g-4 mb-3 ${styles.coursesGrid}`}>
                {usersLoading && <Spinner animation='border' variant='dark' />}
                {showUsersLoadingError && <p>Something went wrong. Please refersh the page.</p>}
                <Col xs={12} md={5} xl={4} className='clearfix'>
                    <Button form="addNewUser" variant="dark" type="submit" className='mb-3 w-100' onClick={() => {
                        setAddNewUserVisible(true);
                        setExistingUserVisible(false);
                    }}>
                       <span className="d-flex align-items-center justify-content-center"><PersonAdd size={18} className='m-1'/> Add New User</span>
                    </Button>
                    {!usersLoading &&
                        <DropdownUsersField
                            users={users}
                            name="users"
                            onUserSelected={(userChoosen) => {
                                setUser(userChoosen);
                                setAddNewUserVisible(false);
                                setExistingUserVisible(true);
                                setDeleteUserVisible(true);
                            }}
                            className='w-100'
                        />
                    }
                    {deleteUserVisible &&
                        <Button form="deleteUser" variant="dark" type="submit" className='mb-3 w-100' onClick={() => {
                            setDeleteUserVisible(false);
                            deleteUser(user!);
                        }}>
                            Delete {user?.lastName + " " + user?.firstName + " (" + user?.neptunCode + ")"}
                        </Button>
                    }
                </Col>

                <Col xs={12} md={6} xl={6} className='clearfix'>
                    {existingUserVisible &&
                        <>
                            {userLoading && <Spinner animation='border' variant='dark' />}
                            {showUserLoadingError && <p>Something went wrong. Please refersh the page.</p>}
                            {(!userLoading && user) &&
                                <UpdateUserDashboard user={user} />
                            }
                        </>
                    }

                    {addNewUserVisible &&
                        <AddNewUserDashboard />
                    }
                </Col>
            </Row>
        </>
    );
}

export default DashboardLoggedInView;